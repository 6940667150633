export const Input = {
  parts: ["field"],
  baseStyle: {
    field: {
      fontFamily: "Larsseit",
      fontFeatureSettings: '"ss01" on, "ss02" on',
      _placeholder: {
        color: "#777",
      },
      _disabled: {
        cursor: "default",
        pointerEvents: "none",
      },
    },
  },
  sizes: {
    default: {},
  },
  variants: {
    readOnly: {
      bgColor: "transparent",
      border: "none",
      outline: "none",
    },
    outline: {
      field: {
        fontSize: "13.33333px",
        lineHeight: "normal",
        borderRadius: "40px",
        p: "18px",
        _focus: {
          borderColor: "brand.black",
        },
      },
    },
    accountFlow: {
      field: {
        fontSize: 16,
        lineHeight: "19.2px",
        border: "1px solid",
        borderColor: "brand.black",
        borderRadius: "40px",
        maxW: "320px",
        mx: "auto",
        px: "18px",
        py: "15px",
        _focus: {
          borderColor: "brand.black",
        },
      },
    },
    address: {
      field: {
        fontSize: 24,
        lineHeight: 1.4,
        pt: "10px",
        borderBottom: "1px solid",
        bgColor: "transparent",
      },
    },
    wishlist: {
      field: {
        fontSize: 18,
        borderBottom: "2px solid",
        borderBottomColor: "brand.darkBlue",
        pb: "10px",
      },
    },
    subscribeDetail: {
      field: {
        fontSize: "14px",
        border: "1px solid",
        borderColor: "primary.greyDisabled",
        boxShadow: "none",
        bg: "brand.white",
        borderRadius: "8px",
        lineHeight: "24px",
        color: "brand.black",
        padding: "15px",
        _placeholder: {
          color: "primary.greyDisabled",
          lineHeight: "24px",
        },
        _focus: {
          _placeholder: {
            color: "brand.grayText",
          },
        },
        _invalid: {
          borderBottomColor: "brand.red",
          borderWidth: 1,
          boxShadow: "none",
        },
      },
    },
    subscribe: {
      field: {
        fontSize: "13.33333px",
        color: "brand.black",
        lineHeight: "normal",
        borderRadius: "40px",
        outline: "none",
        border: "none",
        p: "18px",
        _placeholder: {
          color: "brand.black",
        },
      },
    },
    giftCard: {
      field: {
        fontSize: [16, null, 18],
        lineHeight: ["15px", null, "26px"],
        color: "brand.black",
        bgColor: "primary.white",
        border: "1px solid",
        borderColor: "brand.black",
        borderRadius: "40px",
        p: "18px",
      },
    },
    giftCardAlt: {
      field: {
        fontSize: "14px",
        lineHeight: "24px",
        color: "primary.greyDisabled",
        bgColor: "primary.white",
        border: "1px solid",
        borderColor: "primary.greyDisabled",
        borderRadius: "8px",
        p: "16px",
      },
    },
    skinQuiz: {
      field: {
        fontSize: "18px",
        lineHeight: "normal",
        borderRadius: "40px",
        border: "1px solid #1C1F2A",
        p: "12px",
        textAlign: "center",
      },
    },
    wishlistAlt: {
      field: {
        fontSize: "14px",
        lineHeight: "24px",
        color: "brand.black",
        bgColor: "primary.white",
        border: "1px solid",
        borderColor: "primary.greyDisabled",
        borderRadius: "8px",
        p: "16px",
        _focus: {
          borderColor: "brand.black",
        },
        _placeholder: {
          color: "primary.greyDisabled",
        },
      },
    },
  },
  defaultProps: {
    size: "default",
    variant: "outline",
  },
}
