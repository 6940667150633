import { useCallback, useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useMutation } from "@apollo/client"

import { useShopify } from "@app/hooks/useShopify"
import { useAnalytics } from "@app/hooks/useAnalytics"
import { useQueries } from "@app/hooks/useQueries"
import { useConfigContext } from "@app/providers/config"
import { useCartContext } from "@app/providers/cart"
import { useAppContext } from "@app/providers/app"
import { useEmarsysContext } from "@app/providers/emarsys"
import { useCore } from "@app/hooks/useCore"
import { Attribute, CartUserError } from "@shopify/hydrogen-react/storefront-api-types"
import { NormalisedVariant } from "@root/types/product"

export const useCart = () => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()
  const {
    graphql: {
      mutations: { CART_LINE_ADD, CART_LINE_UPDATE, CART_LINE_REMOVE },
    },
  } = useQueries()
  const {
    store: { giftCardType, minimumOrderValue },
  } = useConfigContext()
  const { cart, id: cartId, saveCart, refreshCartStorage } = useCartContext()
  const { cartNormaliser, formatMoney } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const { trackPage } = useEmarsysContext()
  const { dispatch } = useAppContext()
  const [loading, setLoading] = useState(false)

  const [errors, setErrors] = useState<Array<CartUserError>>([])

  const [cartLinesAdd] = useMutation(CART_LINE_ADD)
  const [cartLinesUpdate] = useMutation(CART_LINE_UPDATE)
  const [cartLinesRemove] = useMutation(CART_LINE_REMOVE)

  const addToCart = useCallback(
    async (product: any, variant: NormalisedVariant, quantity = 1, attributes: Attribute[] = []) => {
      if (!cartId) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)
      if (product?.variants?.length > 1 && attributes?.length === 0 && variant?.title) {
        attributes = [{ key: "_label", value: variant?.title }]
      }

      // Remove empty attributes if any, this causes an error in the cart
      attributes = attributes?.filter(({ value }) => !!value)

      const lines = {
        attributes:
          attributes?.map(({ key, value }) => ({
            key,
            value,
          })) || [],
        quantity: quantity,
        merchandiseId: variant.id,
      }

      const {
        data: { cartLinesAdd: data },
      } = await cartLinesAdd({
        variables: {
          cartId,
          lines,
        },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        refreshCartStorage(normalisedCart)
        saveCart(data.cart)
        trackPage(null, null, normalisedCart)
        dispatch({
          type: "cart",
          payload: true,
        })
        trackCartUpdate(
          "add",
          `gid://shopify/ProductVariant/${decodeShopifyId(variant.id, "ProductVariant")}`,
          quantity,
          normalisedCart?.lines
        )
      }

      setLoading(false)
    },
    [cartLinesAdd, cartId, cartNormaliser, trackCartUpdate, decodeShopifyId, saveCart, trackPage, dispatch, setLoading, refreshCartStorage]
  )

  const addToCartMultiple = useCallback(
    async (
      items: {
        variantId: string
        quantity: number
        attributes: Attribute[]
      }[] = []
    ) => {
      if (!cartId) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)
      const lines =
        items?.map(line => ({
          attributes: (line?.attributes as Attribute[])
            ?.filter(({ value }) => !!value)
            ?.map(({ key, value }) => ({
              key,
              value,
            })),
          quantity: line?.quantity,
          merchandiseId: line?.variantId,
        })) || []

      const {
        data: { cartLinesAdd: data },
      } = await cartLinesAdd({
        variables: {
          cartId,
          lines,
        },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.cart) {
        saveCart(data.cart)
        const normalisedCart = cartNormaliser(data.cart)
        refreshCartStorage(normalisedCart)
        trackPage(null, null, normalisedCart)
        dispatch({
          type: "cart",
          payload: true,
        })
        for (const item of items) {
          trackCartUpdate("add", item.variantId, item.quantity, normalisedCart?.lines)
        }
      }
      setLoading(false)
    },
    [cartLinesAdd, cartId, cartNormaliser, saveCart, trackPage, dispatch, trackCartUpdate, setLoading, refreshCartStorage]
  )

  const removeFromCart = useCallback(
    async (id: string, variantId: string) => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)
      const quantity = cart?.lines.filter(line => line.id === id).map(({ quantity }) => quantity)[0] || 1

      const lineIds = cart?.lines.filter(line => line.id === id).map(line => line.id)

      const {
        data: { cartLinesRemove: data },
      } = await cartLinesRemove({
        variables: { cartId, lineIds },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        refreshCartStorage(normalisedCart)
        saveCart(data.cart)
        trackPage(null, null, normalisedCart)
        trackCartUpdate("remove", variantId, quantity, cart?.lines || [])
      }
      setLoading(false)
    },
    [cart?.lines, trackCartUpdate, cartLinesRemove, cartId, cartNormaliser, saveCart, trackPage, setLoading, refreshCartStorage]
  )

  const updateQuantity = useCallback(
    async (id: string, variantId: string, quantity: number, action = "add") => {
      if (!cartId || !cart) {
        console.error("Cart ID not found")
        return
      }
      setLoading(true)

      const lines = cart?.lines
        .filter(line => line.id === id)
        .map(line => ({
          id: line.id,
          quantity: quantity,
        }))

      const {
        data: { cartLinesUpdate: data },
      } = await cartLinesUpdate({
        variables: { cartId, lines },
      })

      if (data.userErrors?.length) {
        setErrors(data.userErrors)
        setLoading(false)
      }

      if (data?.cart) {
        const normalisedCart = cartNormaliser(data.cart)
        refreshCartStorage(normalisedCart)
        saveCart(data.cart)
        trackPage(null, null, normalisedCart)
        trackCartUpdate(action, variantId, quantity, normalisedCart?.lines)
      }
      setLoading(false)
    },
    [saveCart, cart, cartLinesUpdate, cartId, cartNormaliser, trackCartUpdate, trackPage, setLoading, refreshCartStorage]
  )

  const { shipping, giftWrapping } = useStaticQuery<GatsbyTypes.staticShippingAndPageCartQuery>(graphql`
    query staticShippingAndPageCart {
      shipping: sanitySettingShipping {
        threshold
        includeDiscounts
        messageBefore
        messageAfter
      }
      giftWrapping: sanitySettingGiftWrapping {
        enabled
        product {
          ...SanityProductFragment
        }
        icon
        title
        content: _rawContent(resolveReferences: { maxDepth: 5 })
        image {
          asset {
            url
          }
        }
        color {
          preset
          customCss
          custom {
            hex
          }
        }
        textColor
      }
    }
  `)

  const freeShipping = useMemo(() => {
    const threshold = shipping?.threshold ?? 100
    const includeDiscounts = shipping?.includeDiscounts ?? true
    const total = includeDiscounts ? Number(cart?.cost?.subtotalAmount?.amount) : Number(cart?.cost?.totalAmount?.amount) || 0
    const percentage = total > threshold ? 100 : (total / threshold) * 100
    const remaining = total > threshold ? 0 : threshold - total
    const rawMessage = percentage === 100 ? shipping?.messageAfter : shipping?.messageBefore
    const message = rawMessage?.replace("{threshold}", formatMoney(threshold))?.replace("{remaining}", formatMoney(remaining))
    return { threshold, percentage, message }
  }, [shipping, cart?.cost?.totalAmount?.amount, cart?.cost?.subtotalAmount?.amount, formatMoney])

  const isBelowMinOrder = useMemo(() => {
    const subtotal = Number(cart?.cost?.totalAmount?.amount)
    const isBelowMinOrder = minimumOrderValue > 0 ? subtotal < minimumOrderValue : false
    return isBelowMinOrder
  }, [cart?.cost?.totalAmount?.amount, minimumOrderValue])

  const hasOnlyGiftCards = useMemo(() => {
    const lines = cart?.lines ?? []
    return lines.length > 0 && lines.every(item => item.merchandise.product.productType === giftCardType)
  }, [cart?.lines, giftCardType])

  return {
    addToCart,
    addToCartMultiple,
    removeFromCart,
    updateQuantity,
    freeShipping,
    loading,
    errors,
    giftWrapping,
    isBelowMinOrder,
    hasOnlyGiftCards,
  }
}
