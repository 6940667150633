import { useCallback, useEffect, useMemo, useState } from "react"
import { useLazyQuery, useMutation, useQuery } from "@apollo/client"
import { navigate } from "gatsby"

import { useCore, useStorage } from "@app/hooks/useCore"
import { useFunctions } from "@app/hooks/useFunctions"
import { useConfigContext } from "@app/providers/config"
import { useCustomerContext } from "@app/providers/customer"
import { useShopify } from "@app/hooks/useShopify"
import { useCartContext } from "@app/providers/cart"

export const formatPhoneNumber = (phoneNumber: string, callingCode: string) => {
  // if phone number starts with 0, remove it
  let formattedPhoneNumber = phoneNumber.replace(/^0/, "")
  // align with calling code format
  formattedPhoneNumber = formattedPhoneNumber.slice(0, 1) !== "+" ? formattedPhoneNumber.replace(/^/, "+") : formattedPhoneNumber
  // check if phone number starts with calling code
  const formattedPhoneNumberWithCallingCode = formattedPhoneNumber.startsWith(callingCode)
    ? formattedPhoneNumber
    : `${callingCode}${formattedPhoneNumber}`

  return formattedPhoneNumberWithCallingCode.replaceAll(/\+/g, "")
}

export const useCustomerAccessToken = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_ACCESS_TOKEN_CREATE, CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS },
    },
  } = useCore()
  const { getCustomer, saveCustomer } = useCustomerContext()

  const [customerAccessTokenCreate] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE)
  const [customerAccessTokenCreateWithMultipass] = useMutation(CUSTOMER_ACCESS_TOKEN_CREATE_MULTIPASS)

  const createAccessToken = useCallback(
    async (email, password) => {
      if (email?.length && password?.length) {
        try {
          const {
            data: {
              customerAccessTokenCreate: { customerAccessToken, customerUserErrors },
            },
          } = await customerAccessTokenCreate({
            variables: { input: { email, password } },
          })

          if (customerAccessToken && !customerUserErrors?.length) saveCustomer(customerAccessToken)

          return { customerAccessToken, customerUserErrors }
        } catch (err) {
          console.error(err)
          return null
        }
      }
    },
    [customerAccessTokenCreate, saveCustomer]
  )

  const createAccessTokenWithMultipass = useCallback(
    async multipassToken => {
      try {
        const {
          data: {
            customerAccessTokenCreateWithMultipass: { customerAccessToken, customerUserErrors },
          },
        } = await customerAccessTokenCreateWithMultipass({
          variables: { multipassToken },
        })

        if (customerAccessToken && !customerUserErrors?.length) saveCustomer(customerAccessToken)

        return { customerAccessToken, customerUserErrors }
      } catch (err) {
        console.error(err)
        return null
      }
    },
    [customerAccessTokenCreateWithMultipass, saveCustomer]
  )

  return { createAccessToken, createAccessTokenWithMultipass, getCustomer }
}

export const useCustomerRegister = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_CREATE },
    },
    helpers: { isBrowser, getUrlParameter },
  } = useCore()
  const {
    settings: { params, routes, keys },
  } = useConfigContext()
  const { setStorage } = useStorage()
  const { createAccessToken } = useCustomerAccessToken()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState({
    email: "",
    password: "",
    firstName: "",
    lastName: "",
    acceptsMarketing: true,
    phone: "",
  })

  const [customerCreate] = useMutation(CUSTOMER_CREATE)

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    const filteredData = Object.entries(data).reduce((a, [k, v]) => (v && !["passwordVerify"].includes(k) ? ((a[k] = v), a) : a), {})
    await createCustomer({
      ...filteredData,
    })
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const createCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])
      try {
        const {
          data: {
            customerCreate: { customerUserErrors: errors },
          },
        } = await customerCreate({
          variables: { input: { ...userData } },
        })

        if (errors?.length) {
          setErrors(errors)
          setLoading(false)
        } else {
          const data = await createAccessToken(userData?.email, userData?.password)
          const path = getUrlParameter(params.continue)
          const route = path ? path : routes.DASHBOARD

          setStorage(keys.authenticating, "sign_up")

          //@ts-ignore
          const { customerUserErrors } = data
          if (!customerUserErrors?.length) {
            isBrowser ? navigate(route, { replace: true }) : null
          } else {
            setErrors(customerUserErrors)
            setLoading(false)
          }
        }
      } catch (error) {
        console.error(error)
        setErrors([error.message])
        setLoading(false)
      }
    },
    [customerCreate, createAccessToken, getUrlParameter, params.continue, routes.DASHBOARD, setStorage, keys.authenticating, isBrowser]
  )

  return { createCustomer, handleSubmit, handleChange, data, loading, errors }
}

export const useCustomerLogin = () => {
  const {
    helpers: { getUrlParameter },
  } = useCore()
  const {
    settings: { keys, params, routes },
    store: { subfolder },
  } = useConfigContext()
  const { setStorage } = useStorage()
  const { gotoCheckout } = useCartContext()
  const { customer, saveCustomer } = useCustomerContext()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [loggedIn, setLoggedIn] = useState(false)
  const [data, setData] = useState({ email: "", password: "" })
  const { callFunction } = useFunctions()
  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await loginCustomer({
      ...data,
    })
  }

  useEffect(() => {
    const isCheckout = getUrlParameter(params.checkout)

    if (customer && isCheckout && loggedIn) {
      gotoCheckout()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, getUrlParameter, loggedIn, params.checkout])

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const loginCustomer = useCallback(
    async ({ ...userData }) => {
      setLoading(true)
      setErrors([])

      const path = getUrlParameter(params.continue)
      const route = path ? path?.replace(subfolder, "") : routes.DASHBOARD
      const isCheckout = getUrlParameter(params.checkout)

      try {
        const response = await callFunction("customer-global-login", { email: userData?.email, password: userData?.password })

        if (response?.status === "success" && response?.body?.local) {
          saveCustomer(response?.body?.local)
          setStorage(keys.authenticating, "login")
          if (response?.body?.other) {
            setStorage(keys.customerOther, { ...response?.body?.other })
          }
          setLoggedIn(true)

          if (!isCheckout) navigate(route, { replace: true })
        } else {
          setErrors([response?.body])
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setLoading(false)
      }
    },
    [
      getUrlParameter,
      params.continue,
      params.checkout,
      routes.DASHBOARD,
      callFunction,
      saveCustomer,
      setStorage,
      keys.authenticating,
      keys.customerOther,
      subfolder,
    ]
  )

  return { loginCustomer, data, handleSubmit, handleChange, loading, errors }
}

export const useCustomerLogout = () => {
  const { store } = useConfigContext()
  const {
    helpers: { isBrowser },
  } = useCore()

  const {
    settings: { keys },
  } = useConfigContext()

  const { clearCustomer } = useCustomerContext()
  const { removeStorage } = useStorage()

  const logoutCustomer = useCallback(async () => {
    clearCustomer()
    removeStorage(keys.wishlistRegId)
    removeStorage(keys.wishlistSessionId)
    removeStorage(keys.wishlistSynced)
    if (isBrowser) navigate(`https://${store.shopifyShopDomain}/account/logout`)
  }, [store.shopifyShopDomain, clearCustomer, removeStorage, keys.wishlistRegId, keys.wishlistSessionId, keys.wishlistSynced, isBrowser])

  return { logoutCustomer }
}

export const useCustomerRecover = () => {
  const {
    graphql: {
      mutations: { CUSTOMER_RECOVER },
    },
  } = useCore()
  const { clearCustomer } = useCustomerContext()
  const [customerRecover] = useMutation(CUSTOMER_RECOVER)

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState({ email: "" })
  const [success, setSuccess] = useState(false)

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await recoverCustomer(data?.email)
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const recoverCustomer = useCallback(
    async email => {
      setLoading(true)
      setErrors([])

      try {
        const {
          data: {
            customerRecover: { customerUserErrors },
          },
        } = await customerRecover({
          variables: { email },
        })

        if (!customerUserErrors?.length) {
          clearCustomer()
          setLoading(false)
          setSuccess(true)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setLoading(false)
      }
    },
    [setLoading, setErrors, customerRecover, clearCustomer]
  )

  return { recoverCustomer, data, handleSubmit, handleChange, loading, errors, success }
}

export const useCustomerAccount = () => {
  const {
    helpers: { encodeShopifyId, isBrowser, getUrlParameter },
    graphql: {
      mutations: { CUSTOMER_RESET, CUSTOMER_ACTIVATE },
    },
  } = useCore()
  const {
    settings: { routes, params, keys },
  } = useConfigContext()
  const { saveCustomer } = useCustomerContext()
  const { setStorage } = useStorage()
  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const [data, setData] = useState({ password: "" })

  const [customerReset] = useMutation(CUSTOMER_RESET)
  const [customerActivate] = useMutation(CUSTOMER_ACTIVATE)

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) => {
    setData(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))
  }

  const { resetCustomerId, resetToken } = useMemo(() => {
    const resetParams = getUrlParameter(params?.customer)?.split("/") || []
    const resetCustomerId = resetParams?.[0] || null
    const resetToken = resetParams?.[1] || null
    if (!resetCustomerId || !resetToken) setErrors(["Your reset password link is invalid."])
    return { resetCustomerId, resetToken }
  }, [getUrlParameter, params?.customer])

  const handleResetSubmit = async event => {
    event.preventDefault()
    await resetCustomer(resetCustomerId, resetToken, data?.password)
  }

  const resetCustomer = useCallback(
    async (customerId, resetToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = encodeShopifyId(customerId, "Customer")

        const {
          data: {
            customerReset: { customerAccessToken, customerUserErrors },
          },
        } = await customerReset({
          variables: { id, input: { resetToken, password } },
        })

        if (!customerUserErrors?.length) {
          saveCustomer(customerAccessToken)
          if (isBrowser) navigate(routes.DASHBOARD)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setLoading(false)
      }
    },
    [encodeShopifyId, setLoading, setErrors, customerReset, saveCustomer, isBrowser, routes]
  )

  const { activateCustomerId, activationToken } = useMemo(() => {
    const activateParams = getUrlParameter(params?.customer)?.split("/") || []
    const activateCustomerId = activateParams?.[0] || null
    const activationToken = activateParams?.[1] || null
    if (!activateCustomerId || !activationToken) setErrors(["Your activate account link is invalid."])
    return { activateCustomerId, activationToken }
  }, [getUrlParameter, params?.customer])

  const handleActivateSubmit = async (event: any) => {
    event.preventDefault()
    await activateCustomer(activateCustomerId, activationToken, data?.password)
  }

  const activateCustomer = useCallback(
    async (customerId, activationToken, password) => {
      setLoading(true)
      setErrors([])

      try {
        const id = btoa(`gid://shopify/Customer/${customerId}`)

        const {
          data: {
            customerActivate: { customerAccessToken, customerUserErrors },
          },
        } = await customerActivate({
          variables: { id, input: { activationToken, password } },
        })

        if (!customerUserErrors?.length) {
          saveCustomer(customerAccessToken)
          setStorage(keys.authenticating, "sign_up")
          if (isBrowser) navigate(routes.DASHBOARD)
        } else {
          setErrors(customerUserErrors)
          setLoading(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setLoading(false)
      }
    },
    [customerActivate, saveCustomer, setStorage, keys.authenticating, isBrowser, routes.DASHBOARD]
  )

  return { resetCustomer, activateCustomer, data, setData, handleChange, handleActivateSubmit, handleResetSubmit, loading, errors }
}

export const useCustomerOrders = (first: number) => {
  const { store } = useConfigContext()
  const {
    graphql: {
      queries: { GET_CUSTOMER_ORDERS },
    },
  } = useCore()
  const { getStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const { orderNormaliser } = useShopify()
  const { accessToken: customerAccessToken } = getStorage(keys.customer) || { accessToken: "" }

  const { data, loading, error } = useQuery(GET_CUSTOMER_ORDERS, {
    variables: {
      countryCode: store?.locationRegion,
      customerAccessToken,
      first,
      reverse: true,
    },
  })

  const orders = orderNormaliser(data?.customer?.orders)?.filter((order: any) => order?.id)

  return { orders, loading, error }
}

export const useCustomerOrder = (orderId: string, key: string) => {
  const {
    helpers: { encodeBase64 },
    graphql: {
      queries: { GET_ORDER },
    },
  } = useCore()
  const { orderNormaliser } = useShopify()
  const id = encodeBase64(`gid://shopify/Order/${orderId}${key}`)

  const { data, loading, error } = useQuery(GET_ORDER, {
    variables: {
      id,
    },
  })

  const order = data?.node ? orderNormaliser(data?.node)?.[0] : null

  return { order, loading, error }
}

export const useCustomerAddress = (customer: any) => {
  const {
    helpers: { edgeNormaliser },
    graphql: {
      mutations: { CUSTOMER_ADDRESS_CREATE, CUSTOMER_ADDRESS_UPDATE, CUSTOMER_ADDRESS_DELETE, CUSTOMER_DEFAULT_ADDRESS_UPDATE },
      queries: { GET_CUSTOMER },
    },
  } = useCore()
  const { getStorage } = useStorage()
  const {
    store,
    settings: { keys },
  } = useConfigContext()
  const { addressNormaliser } = useShopify()
  const { setStorage } = useStorage()
  const [saving, setSaving] = useState(false)
  const [errors, setErrors] = useState<Array<string>>([])
  const initialData = useMemo(
    () => ({
      address1: "",
      address2: "",
      city: "",
      company: "",
      country: "Australia",
      firstName: "",
      lastName: "",
      phone: "",
      province: "",
      zip: "",
    }),
    []
  )
  const initialAddresses = customer?.addresses ? edgeNormaliser(customer?.addresses)?.map(addressNormaliser) : null
  const [address, setAddress] = useState({ ...initialData, id: "", action: "" })
  const [addresses, setAddresses] = useState(initialAddresses)
  const { accessToken: customerAccessToken } = getStorage(keys.customer) || { accessToken: "" }

  const [customerAddressCreate] = useMutation(CUSTOMER_ADDRESS_CREATE)
  const [customerAddressUpdate] = useMutation(CUSTOMER_ADDRESS_UPDATE)
  const [customerAddressDelete] = useMutation(CUSTOMER_ADDRESS_DELETE)
  const [customerDefaultAddressUpdate] = useMutation(CUSTOMER_DEFAULT_ADDRESS_UPDATE)

  const filterAndFormatData = useCallback(
    async address => {
      const filteredAddress = Object.keys(address)
        .filter(key => Object.keys(initialData).includes(key))
        .reduce((obj: any, key: string) => {
          obj[key] = address[key]
          return obj
        }, {})

      try {
        const formattedAddress = {
          ...filteredAddress,
          phone: filteredAddress?.phone ? formatPhoneNumber(filteredAddress.phone, country_calling_code) : filteredAddress?.phone,
        }
        return formattedAddress
      } catch {
        return filteredAddress
      }
    },
    [initialData]
  )

  const [getAll, { data, loading }] = useLazyQuery(GET_CUSTOMER, {
    fetchPolicy: "no-cache",
    variables: {
      customerAccessToken,
      countryCode: store?.locationRegion,
    },
  })

  useEffect(() => {
    getAll()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (saving) getAll()
  }, [saving, getAll])

  // heavy handed fix for the above useEffect firing continiously, resulting in max depth exceeded...
  useEffect(() => {
    if (data?.customer) {
      const newAddresses = edgeNormaliser(data.customer.addresses).map((address: any) => ({
        ...address,
        default: address.id === data.customer.defaultAddress.id,
      }))

      if (JSON.stringify(newAddresses) !== JSON.stringify(addresses)) {
        setAddresses(newAddresses)
      }
    }
  }, [data, edgeNormaliser, addresses])

  const defaultAddress = useCallback(
    async addressId => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerDefaultAddressUpdate: { customerUserErrors },
          },
        } = await customerDefaultAddressUpdate({
          variables: { addressId, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          setSaving(false)
          setStorage(keys.updatingAccount, "address")
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [customerDefaultAddressUpdate, customerAccessToken, setStorage, keys.updatingAccount]
  )

  const createAddress = useCallback(
    async address => {
      setSaving(true)
      setErrors([])

      try {
        const formattedAddress = await filterAndFormatData(address)
        const {
          data: {
            customerAddressCreate: { customerAddress, customerUserErrors },
          },
        } = await customerAddressCreate({
          variables: { customerAccessToken, address: formattedAddress },
        })

        if (address.default) await defaultAddress(customerAddress?.id)

        if (!customerUserErrors?.length) {
          getAll()
          setAddress({ ...initialData, id: "", action: "" })
          setStorage(keys.updatingAccount, "address")
          setSaving(false)
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [filterAndFormatData, customerAddressCreate, customerAccessToken, defaultAddress, getAll, initialData, setStorage, keys.updatingAccount]
  )

  const updateAddress = useCallback(
    async (id, address, isDefault) => {
      setSaving(true)
      setErrors([])

      try {
        const formattedAddress = await filterAndFormatData(address)
        const {
          data: {
            customerAddressUpdate: { customerUserErrors },
          },
        } = await customerAddressUpdate({
          variables: { customerAccessToken, id, address: formattedAddress },
        })

        if (!customerUserErrors?.length) {
          if (isDefault) {
            await customerDefaultAddressUpdate({
              variables: { addressId: id, customerAccessToken },
            })
          }
          getAll()

          setAddress({ ...initialData, id: "", action: "" })
          setSaving(false)
          setStorage(keys.updatingAccount, "address")
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [
      filterAndFormatData,
      customerAddressUpdate,
      customerAccessToken,
      getAll,
      initialData,
      setStorage,
      keys.updatingAccount,
      customerDefaultAddressUpdate,
    ]
  )

  const deleteAddress = useCallback(
    async id => {
      setSaving(true)
      setErrors([])

      try {
        const {
          data: {
            customerAddressDelete: { customerUserErrors },
          },
        } = await customerAddressDelete({
          variables: { id, customerAccessToken },
        })

        if (!customerUserErrors?.length) {
          getAll()
          setSaving(false)
          setStorage(keys.updatingAccount, "address")
        } else {
          setErrors(customerUserErrors)
          setSaving(false)
        }
      } catch (err) {
        console.error(err)
        setErrors([(err as Error).message])
        setSaving(false)
      }
    },
    [customerAddressDelete, customerAccessToken, getAll, setStorage, keys.updatingAccount]
  )

  return {
    addresses,
    setAddress,
    address,
    createAddress,
    updateAddress,
    defaultAddress,
    deleteAddress,
    initialData,
    loading,
    saving,
    errors,
  }
}

export const useCustomerDetails = () => {
  const { loginCustomer } = useCustomerLogin()
  const {
    graphql: {
      mutations: { CUSTOMER_UPDATE },
    },
  } = useCore()
  const { getStorage, setStorage } = useStorage()
  const {
    settings: { keys },
  } = useConfigContext()
  const { formatErrors } = useShopify()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState<any>()
  const [success, setSuccess] = useState(false)
  const initialData = useMemo(
    () => ({
      firstName: "",
      lastName: "",
      email: "",
      phone: "",
      password: "",
      acceptsMarketing: false,
    }),
    []
  )
  const [customerUpdate] = useMutation(CUSTOMER_UPDATE)
  const { customer: initialCustomer, setCustomer: saveCustomer } = useCustomerContext()
  const [customer, setCustomer] = useState(initialCustomer)
  const { accessToken: customerAccessToken } = getStorage(keys.customer) || { accessToken: "" }

  const filterData = useCallback(
    (data, hidePassword = false) => {
      return hidePassword
        ? Object.keys(data)
            .filter(key => Object.keys(initialData).includes(key))
            .filter(key => key !== "password")
            .reduce((obj: any, key: string) => {
              obj[key] = data[key]
              return obj
            }, {})
        : Object.keys(data)
            .filter(key => Object.keys(initialData).includes(key))
            .reduce((obj: any, key: string) => {
              obj[key] = data[key]
              return obj
            }, {})
    },
    [initialData]
  )

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault()
    await updateCustomer(customer)
  }

  const handleChange = ({ target: { type, name, value, checked } }: React.ChangeEvent<HTMLInputElement>) =>
    setCustomer(prevState => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }))

  const updateCustomer = useCallback(
    async customer => {
      setLoading(true)
      setErrors([])

      try {
        const data = filterData(customer)
        const {
          data: {
            customerUpdate: { customerUserErrors },
          },
        } = await customerUpdate({
          variables: { customerAccessToken, customer: data },
        })

        if (!customerUserErrors?.length) {
          saveCustomer((prevCustomer: any) => ({
            ...prevCustomer,
            ...filterData(customer, true),
          }))
          if (data?.password) {
            await loginCustomer({ email: data?.email, password: data?.password })
          }
          setStorage(keys.updatingAccount, "account")
          setLoading(false)
          setSuccess(true)
        } else {
          setErrors(formatErrors(customerUserErrors))
          setLoading(false)
        }
      } catch (err) {
        setErrors(formatErrors(err))
        setLoading(false)
      }
    },
    [filterData, customerUpdate, customerAccessToken, saveCustomer, loginCustomer, formatErrors, keys.updatingAccount, setStorage]
  )

  return { customer, setCustomer, updateCustomer, handleSubmit, handleChange, loading, success, errors }
}
