import React, { useEffect, useReducer, useRef, useState } from "react"
import { useStorage } from "@app/hooks/useCore"
import { useConfigContext } from "@app/providers/config"

type ContextProps = {
  state: {
    activeHeader: any
    activeMenu: boolean
    activeCart: boolean
    activeSearch: boolean
    activeSubscribe: boolean
    geolocationDismissed: boolean
    geolocationOpen: boolean
  }
  dispatch: React.Dispatch<any>
  headerRef: any
  activeOptions: any | null
  setActiveOptions: (state: any) => void
  activeVariant: any | null
  setActiveVariant: (state: any) => void
  activeProduct: any | null
  setActiveProduct: (state: any) => void
  activeCollection: any | null
  setActiveCollection: (state: any) => void
  setGeolocationDismissed: (state: any) => void
  setGeolocationOpen: (state: any) => void
  activeHeaderMegaItem: any | null
  setActiveHeaderMegaItem: (state: any) => void
}

export const AppContext = React.createContext<ContextProps | undefined>(undefined)

export const AppProvider: React.FC = ({ children }) => {
  const {
    settings: { keys },
  } = useConfigContext()
  const { getStorage } = useStorage()
  const headerRef = useRef<any>()
  const [activeOptions, setActiveOptions] = useState(null)
  const [activeHeaderMegaItem, setActiveHeaderMegaItem] = useState(null)
  const [activeVariant, setActiveVariant] = useState(null)
  const [activeProduct, setActiveProduct] = useState(null)
  const [activeCollection, setActiveCollection] = useState(null)
  const [geolocationDismissed, setGeolocationDismissed] = useState(null)
  const [geolocationOpen, setGeolocationOpen] = useState(null)

  const initialValues = {
    activeMenu: false,
    activeCart: false,
    activeSearch: false,
    activeSubscribe: false,
    geolocationDismissed: getStorage(keys.geolocationDismissed),
    geolocationOpen: false,
  }

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "initial":
        return { ...state, ...initialValues }
      case "header":
        return {
          ...state,
          activeHeader: action.payload,
          activeMenu: false,
          activeSearch: false,
          activeCart: false,
          activeSubscribe: false,
        }
      case "menu":
        return {
          ...state,
          activeMenu: action.payload,
          activeHeader: false,
          activeSearch: false,
          activeCart: false,
          activeSubscribe: false,
        }
      case "cart":
        return {
          ...state,
          activeCart: action.payload,
          activeHeader: false,
          activeSearch: false,
          activeMenu: false,
          activeSubscribe: false,
        }
      case "search":
        return {
          ...state,
          activeSearch: action.payload,
          activeHeader: false,
          activeCart: false,
          activeMenu: false,
          activeSubscribe: false,
        }
      case "subscribe":
        return {
          ...state,
          activeSubscribe: action.payload,
          activeHeader: false,
          activeSearch: false,
          activeCart: false,
          activeMenu: false,
        }
      case "geolocationDismissed":
        return {
          ...state,
          geolocationDismissed: action.payload,
        }
      case "geolocationOpen":
        return {
          ...state,
          geolocationOpen: action.payload,
        }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialValues)

  useEffect(() => {
    if (state?.activeMenu) {
      document.body.style.overflow = "hidden"
    } else {
      document.body.style.overflow = "unset"
    }
  }, [state?.activeMenu])

  const contextValue = React.useMemo<ContextProps>(
    () => ({
      state,
      dispatch,
      headerRef,
      activeOptions,
      setActiveOptions,
      activeVariant,
      setActiveVariant,
      activeProduct,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
      geolocationDismissed,
      setGeolocationDismissed,
      geolocationOpen,
      setGeolocationOpen,
      activeHeaderMegaItem,
      setActiveHeaderMegaItem,
    }),
    [
      state,
      dispatch,
      headerRef,
      activeOptions,
      setActiveOptions,
      activeVariant,
      setActiveVariant,
      activeProduct,
      setActiveProduct,
      activeCollection,
      setActiveCollection,
      geolocationDismissed,
      setGeolocationDismissed,
      geolocationOpen,
      setGeolocationOpen,
      activeHeaderMegaItem,
      setActiveHeaderMegaItem,
    ]
  )

  return <AppContext.Provider value={contextValue}>{children}</AppContext.Provider>
}

export const useAppContext = (): ContextProps => ({ ...React.useContext(AppContext) } as ContextProps)
