import { useStaticQuery, graphql } from "gatsby"

export const useAnnouncement = () => {
  const { data } = useStaticQuery<GatsbyTypes.StaticAnnouncementsQuery>(graphql`
    query StaticAnnouncements {
      data: sanitySettingAnnouncement {
        announcements {
          ...LinkFragment
        }
        backgroundColor {
          preset
        }
      }
    }
  `)

  const items = data?.announcements || []

  return {
    items,
    defaultBgColour: data?.backgroundColor?.preset || "#F6FCF0",
  }
}
