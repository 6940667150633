import { useEffect, useState } from "react"
import { useShopify } from "@app/hooks/useShopify"
import { NormalisedProduct } from "@root/types/product"

export const useLiveProducts = (products: any, useProductsPlaceholder = true, disabled = false) => {
  const { getHandle, productNormaliser, getProductsDict } = useShopify()
  const [liveProducts, setLiveProducts] = useState<NormalisedProduct[]>(useProductsPlaceholder ? products : null)

  // Create a key from the products to trigger a refetch when the products change
  const productsKey = products
    ?.map((item: any) => getHandle(item))
    .filter((item: any) => item !== undefined)
    .join("-")
    ?.toString()

  useEffect(() => {
    const fetchItems = async () => {
      const handles = products?.map((item: any) => getHandle(item)).filter((item: any) => item !== undefined)
      if (handles?.length === 0) {
        setLiveProducts([])
        return
      }

      const dict = await getProductsDict({ firstImages: 2, firstVariants: 1, handles })
      setLiveProducts(
        products
          ?.map((product: any, index: number) => {
            const normalisedLiveProduct = productNormaliser(dict[`product${handles?.[index]?.replace(/-/g, "")}`])
            return normalisedLiveProduct?.handle
              ? {
                  ...normalisedLiveProduct,
                  primaryCollection: product?.primaryCollection,
                  siblings: product?.siblings,
                }
              : null
          })
          ?.filter((product: NormalisedProduct) => !!product)
      )
    }

    if (!disabled) fetchItems()

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productsKey, disabled])

  return {
    liveProducts,
  }
}
