const envConfigs = {
  "allkinds-au": require(`./config.allkinds-au.js`),
  "allkinds-us": require(`./config.allkinds-us.js`),
  "allkinds-staging": require(`./config.allkinds-staging.js`),
}

module.exports = {
  store: envConfigs[`${process.env.GATSBY_ACTIVE_STORE}`],
  stores: Object.entries(envConfigs)
    .filter(([key]) => (process.env.GATSBY_ACTIVE_STORE.includes("staging") ? key.includes("staging") : !key.includes("staging")))
    .reduce((all, store) => ({ ...all, [store[0]]: store[1] }), {}),
  theme: {
    color: "#000000",
    background: "#FFFFFF",
    display: "minimal-ui",
    icon: "static/images/icon.png",
    logo: "static/images/logo.svg",
  },
  settings: {
    optimizelyID: "26167860648",
    product: {
      sizeOptionName: "Size",
      colourOptionName: "Color",
      giftcardHandle: "gift-card",
      denomination: "Denomination",
      design: "Design",
      comingSoon: "coming-soon",
      outOfStock: "out-of-stock",
      backSoon: "back-soon",
      inStore: "in-store",
    },
    reactify: {
      includeFields: ["handle", "images", "presentment_price_ranges", "product_type", "tags", "variants"],
      excludeFields: ["link"],
    },
    cart: {
      giftWrapAttribute: "_is-wrap",
      gwpAttribute: "_is-gwp",
      gwpAutoAdd: "_auto-add",
      giftUpsellText: "_additionalText",
      giftUpsellTextColor: "_additionalTextColor",
      giftcardRecipient: "_gift_recipient_name",
      giftSenderName: "_gift_sender_name",
      giftRecipientEmail: "_gift_recipient_email",
      giftRecipientDeliveryDate: "_gift_recipient_delivery_date",
      giftRecipientMessage: "_gift_recipient_message",
      giftRecipientItemRef: "_gift_recipient_item_ref",
      // 0.00347222222 is 5 minutes in days (for testing)
      // Update to 2.0 for 2 days before going to production
      expiryTimeInDays: 2.0,
    },
    customer: {
      tagPrefix: "preferences",
    },
    keys: {
      market: `market`,
      shopify: `shopify`,
      country: `country`,
      location: `location`,
      cart: `cart`,
      discounts: `discounts`,
      customer: `customer`,
      customerOther: `customerOther`,
      newsletter: `subscribe`,
      announcement: `announcement`,
      maintenance: `maintenance`,
      password: `password`,
      place: `place`,
      position: `position`,
      collectionScroll: `collectionScroll`,
      wishlistRegId: `wishlist`,
      wishlistSessionId: `wishlistSessionId`,
      wishlistSynced: `wishlistSynced`,
      geolocationDismissed: `geolocationDismissed`,
      promoActive: `promo`,
      collectionGridLayout: `collectionGridLayout`,
      authenticating: "authenticating",
      updatingAccount: "updatingAccount",
    },
    params: {
      checkout: "checkout_url",
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      preview: "preview",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      ERROR: "/404",
      CART: "/cart",
      SEARCH: "/search",
      PASSWORD: "/password",
      SAVED: "/wishlist",
      WISHLIST_SHARED: "/wishlist/shared",
      GIFTCARD: "/balance",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      INVALID: "/account/invalid_token",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      DETAILS: "/account/details",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      TV: "/tv",
      TV_SHOWS: "/shows",
      WISHLIST: "/wishlist",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLE: "/articles",
      CATEGORY: "/articles/category",
      STORE: "/stores",
      INGREDIENTS: "/ingredients",
      INGREDIENT_CLASSES: "/ingredientclasses",
    },
    defaultRedirects: [],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/wishlist",
      "/search",
      "/cart",
    ],
    emarsys: {
      updateContactsAPI: "https://api.emarsys.net/api/v2/contact/?create_if_not_exists=1",
      triggerContactRegistrationAPI: "https://api.emarsys.net/api/v2/form/",
    },
  },
}
