import React, { useCallback, useEffect } from "react"
import { Box, Button, Center, Flex } from "@chakra-ui/react"

import { useCore } from "@app/hooks/useCore"
import { useNavigation } from "@app/hooks/useNavigation"
import { useAppContext } from "@app/providers/app"
import { useMedia } from "@app/hooks/useMedia"
import Brand from "@app/components/Brand"
import { CartWidget } from "@app/components/Cart/CartWidget"
import WishlistWidget from "@app/components/Wishlist/WishlistWidget"
import AccountWidget from "@app/components/Account/Widget/AccountWidget"
import SearchWidget from "@app/components/Search/Widget/SearchWidget"
import NavigationHeaderWidget from "@app/components/Navigation/Header/NavigationHeaderWidget"
import NavigationHeader from "@app/components/Navigation/Header/NavigationHeader"
import Stripes from "@app/components/Stripes"
import Icon from "@app/components/Icon"
import Link from "@app/components/Link"
import Announcement from "@app/components/Announcement"
import StoreWidget from "@app/components/Store/StoreWidget"

const NavigationHeaderMenu = React.lazy(() => import("@app/components/Navigation/Header/NavigationHeaderMenu"))
const SearchDropdown = React.lazy(() => import("@app/components/Search/Widget/SearchDropdown"))

type Props = {
  isHomePage?: boolean
}

const Header: React.FC<Props> = ({ isHomePage }) => {
  const { state, dispatch, headerRef } = useAppContext()
  const { isMedium } = useMedia()

  const {
    helpers: { isBrowser },
  } = useCore()
  const { mobilePrimary, mobileSecondary, desktopPrimary } = useNavigation()

  const onActiveDesktopMenuToggle = useCallback(() => {
    dispatch({
      type: "menu",
      payload: !state.activeMenu,
    })
  }, [dispatch, state.activeMenu])

  const onHeaderToggle = useCallback(
    (payload: any) => {
      dispatch({
        type: "header",
        payload: payload,
      })
    },
    [dispatch]
  )

  const onLogoClick = useCallback(() => {
    dispatch({
      type: "header",
      payload: null,
    })
  }, [dispatch])

  useEffect(() => {
    if (isMedium) {
      dispatch({
        type: "menu",
        payload: false,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMedium])

  return (
    <>
      <Announcement />
      <Box
        as="header"
        ref={headerRef}
        bgColor="preset.white"
        pos="sticky"
        top={0}
        zIndex={900}
        transition="background-color .4s ease-in-out"
      >
        <Stripes />
        <Flex
          justifyContent="center"
          width="100%"
          onClick={e => {
            if ((state.activeHeader && e.target === e.currentTarget) || (state.activeHeader && e.currentTarget.contains(e.target))) {
              onHeaderToggle(null)
            }
          }}
        >
          <Flex justify="space-between" align="center" h={{ base: 16, md: 13 }} width="100%" maxW="1500px">
            <Flex alignItems="center" ml={4} display={{ base: "flex", md: "none" }}>
              {state.activeMenu ? (
                <Button onClick={onActiveDesktopMenuToggle}>
                  <Icon name="close" color="preset.white" width={12} height={12} p={2.5} />
                </Button>
              ) : (
                <NavigationHeaderWidget />
              )}
              <Box display={{ sm: "none" }}>
                <SearchWidget color={state.activeMenu ? "brand.white" : "brand.black"} />
              </Box>
            </Flex>
            <NavigationHeader items={desktopPrimary} ml={{ base: 5, sm: 5, lg: 10 }} onHeaderToggle={onHeaderToggle} />
            <Center pr={{ base: 0.25, lg: 0 }} pt={1} position="absolute" left="50%" transform="translateX(-50%)">
              <Link to="/" onClick={onLogoClick}>
                <Brand
                  color="preset.black"
                  animationMode="hover"
                  animationBaseDotColor="brand.blue"
                  width={31}
                  tag={isHomePage ? "h1" : "div"}
                />
              </Link>
            </Center>
            <Flex justifyContent="flex-end" alignItems="center" mr={{ base: 4, sm: 5, lg: 10 }}>
              <Box display={{ base: "none", sm: "block" }} onClick={(e: React.MouseEvent<HTMLDivElement>) => e.stopPropagation()}>
                <SearchWidget color="preset.black" />
              </Box>
              <StoreWidget />
              <AccountWidget color="preset.black" />
              <WishlistWidget color="preset.black" />
              <CartWidget light={false} />
            </Flex>
          </Flex>
        </Flex>
        {isBrowser && (
          <React.Suspense fallback={<div />}>
            <NavigationHeaderMenu items={mobilePrimary} footerItems={mobileSecondary} isOpen={state.activeMenu} />
          </React.Suspense>
        )}
        <React.Suspense fallback={<div />}>
          <SearchDropdown />
        </React.Suspense>
      </Box>
    </>
  )
}

export default React.memo(Header)
