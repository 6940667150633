import { Input } from "."

export const Select = {
  baseStyle: {
    field: {
      ...Input.baseStyle.field,
    },
  },
  sizes: {
    default: {},
  },
  variants: {
    address: {
      field: {
        ...Input.variants.address.field,
        _focus: {
          borderColor: "brand.blue",
        },
      },
    },
  },
  defaultProps: {
    size: "default",
    variant: "outline",
  },
}
