import { useCallback, useState } from "react"

type Props = {
  initialValue?: string
  validateFn?: (value: any) => boolean
  callbackFn?: () => void
  trim?: boolean
}

export const useInput = ({ initialValue, validateFn, callbackFn, trim = false }: Props) => {
  const [value, setValue] = useState(initialValue ?? "")
  const [isValid, setIsValid] = useState<boolean | null>(null)

  const onChange = useCallback(
    event => {
      const value = trim ? event?.target?.value?.trim() : event?.target?.value
      setValue(value)
      if (validateFn) setIsValid(validateFn(value))
      if (callbackFn) callbackFn()
    },
    [setValue, setIsValid, validateFn, callbackFn, trim]
  )

  return {
    value,
    onChange,
    setValue,
    isValid,
    reset: () => setValue(""),
    bind: {
      value,
      onChange,
    },
  }
}
