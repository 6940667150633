import React, { useCallback, useMemo } from "react"
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AspectRatio, Box, Button, Flex, Text, Image } from "@chakra-ui/react"

import { useCartContext } from "@app/providers/cart"
import { useConfigContext } from "@app/providers/config"
import { useShopifyProductLive, useShopifyProductRaw } from "@app/hooks/useShopify"
import { useShopifyPrice } from "@app/hooks/useShopify"
import { useCart } from "@app/hooks/useCart"
import { useColorPreset } from "@app/hooks/useColorPreset"
import Icon from "@app/components/Icon"
import ResolvedContent from "@app/components/ResolvedContent"

type Props = {
  listRef?: React.Ref<HTMLDivElement>
}

export const CartGiftWrap: React.FC<Props> = React.memo(({ listRef }) => {
  const { count, giftWrapActive } = useCartContext()
  const {
    settings: { cart },
  } = useConfigContext()
  const { addToCart, loading: cartLoading, giftWrapping } = useCart()
  const { product: rawProduct } = useShopifyProductRaw(giftWrapping?.product)
  const { product: liveProduct, loading } = useShopifyProductLive(rawProduct)
  const promoProduct = liveProduct || rawProduct
  const { color } = useColorPreset(giftWrapping?.color)
  const resolvedTextColor = `brand.${giftWrapping?.textColor === "light" ? "white" : "black"}`

  const { formattedPrice } = useShopifyPrice(promoProduct?.variants[0], 1)

  const promoAvailable = useMemo(
    () => promoProduct?.published && promoProduct?.variants[0]?.availableForSale,
    [promoProduct?.variants, promoProduct?.published]
  )

  const handleAddToCart = useCallback(async () => {
    if (promoAvailable && !loading && !cartLoading) {
      await addToCart(promoProduct, promoProduct?.variants[0], 1, [
        {
          key: cart.giftWrapAttribute,
          value: "true",
        },
      ])
      if (listRef?.current) listRef.current.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [listRef, addToCart, loading, cartLoading, promoAvailable, promoProduct, cart.giftWrapAttribute])

  return giftWrapping && count > 0 && promoProduct && promoAvailable && !loading && !giftWrapActive ? (
    <>
      <Accordion allowToggle>
        <AccordionItem border="unset">
          {({ isExpanded }) => (
            <>
              <AccordionButton bg={color} color={resolvedTextColor} _hover={{ bgColor: color }} py={3.5} px={5}>
                <Flex alignItems="center" justifyContent="space-between" w="full">
                  <Flex alignItems="center" justifyContent="space-between">
                    <Text variant="text14">{giftWrapping.title}</Text>
                    {giftWrapping.icon && (
                      <Box w="30px" ml={1} sx={{ svg: { mx: "auto" } }} dangerouslySetInnerHTML={{ __html: giftWrapping.icon }} />
                    )}
                  </Flex>
                </Flex>
                <Box>
                  <Icon name="minus" width="12px" height="12px" display={isExpanded ? "block" : "none"} />
                  <Icon name="plus" width="12px" height="12px" display={!isExpanded ? "block" : "none"} />
                </Box>
              </AccordionButton>
              <AccordionPanel p={5}>
                <Flex alignItems="flex-start" justifyContent="space-between">
                  <AspectRatio flexShrink={0} ratio={68 / 85} w={"68px"}>
                    <>{giftWrapping.image?.asset?.url && <Image src={giftWrapping.image.asset.url} pointerEvents="none" />}</>
                  </AspectRatio>
                  <Flex
                    alignItems="flex-start"
                    justifyContent="space-between"
                    flexDirection={{ base: "column", sm: "row" }}
                    pl={{ base: 4, md: 10 }}
                  >
                    {giftWrapping.content && (
                      <ResolvedContent
                        content={giftWrapping.content}
                        sx={{
                          ".content-p": {
                            fontSize: { base: "10px", sm: "xs" },
                            m: 0,
                            mb: 2,
                          },
                        }}
                      />
                    )}
                    <Box pl={{ base: 0, md: 8 }} flexShrink={0} pt={{ base: 2, sm: 0 }}>
                      <Button onClick={() => handleAddToCart()} px={4} variant="fillSmall" isLoading={cartLoading || loading}>
                        Add {formattedPrice}
                      </Button>
                    </Box>
                  </Flex>
                </Flex>
              </AccordionPanel>
            </>
          )}
        </AccordionItem>
      </Accordion>
    </>
  ) : null
})
