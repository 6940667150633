import React, { useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Helmet } from "react-helmet"
import { useMeta } from "@app/hooks/useMeta"
import { useConfigContext } from "@app/providers/config"

import type { Location } from "@root/types/global"

import CookieConsent, { getCookieConsentValue } from "react-cookie-consent"
import { useCore } from "@app/hooks/useCore"
import { useAnalytics } from "@app/hooks/useAnalytics"
import Link from "@app/components/Link"

type Props = {
  breadcrumbs?: Array<any>
  data: any
  location: Location
}

const Meta: React.FC<Props> = ({ breadcrumbs = [], data, location }) => {
  const {
    helpers: { sanityContent },
  } = useCore()
  const { getData, getLanguages, getSchemas, getTags, getTracking } = useMeta()
  const {
    settings: { routes, optimizelyID },
  } = useConfigContext()

  const { trackGDPRConsent, tracked } = useAnalytics()

  const { store } = useConfigContext()
  const url =
    location?.pathname?.startsWith("/au") || location?.pathname?.startsWith("/us") ? location?.pathname?.substring(3) : location?.pathname
  const languages = getLanguages(url)

  const metadata = getData({
    url,
    data,
    routes,
    breadcrumbs,
    template: data?.template?.metadata,
    language: languages?.find(({ primary }) => primary)?.language?.split("_")?.[0] || "",
  })
  const metatags = getTags(metadata)
  const schemas = getSchemas(metadata)
  const tracking = getTracking()
  const search = location?.search
  const gdprCookieName = "cookieConsent"

  const { gtm, banner } = useStaticQuery<GatsbyTypes.StaticGTMQuery>(graphql`
    query StaticGTM {
      gtm: sanitySettingTracking {
        googleTagManagerId
      }
      banner: sanitySettingTracking {
        enabled: cookieConsentBanner
        message: _rawCookieConsentBannerMessage(resolveReferences: { maxDepth: 4 })
        buttonText: cookieConsentAcceptButtonText
        declineButtonText: cookieConsentDeclineButtonText
      }
    }
  `)

  useEffect(() => {
    if (tracked) {
      if (!banner?.enabled || getCookieConsentValue(gdprCookieName) === "true") {
        trackGDPRConsent()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracked])

  return (
    <>
      <Helmet meta={metatags}></Helmet>
      <Helmet htmlAttributes={{ lang: metadata?.metaLang }} title={metadata?.title}>
        <link href={metadata?.siteURL} rel="home" />
        <link href={metadata?.canonical} rel="canonical" />
        <link href="https://apis.google.com/" rel="dns-prefetch" />
        <link href="https://www.googletagmanager.com/" rel="dns-prefetch" />
        <link href={`https://www.googletagmanager.com/gtm.js?id=${gtm?.googleTagManagerId}`} rel="preload" as="script" />
        <link href={`https://www.googletagmanager.com/gtag/js?id=${gtm?.googleTagManagerId}`} rel="preload" as="script" />
        <script type="text/javascript">{`
          var ScarabQueue = ScarabQueue || [];
          (function(id) {
            if (document.getElementById(id)) return;
            var js = document.createElement('script'); js.id = id;
            js.src = '//cdn.scarabresearch.com/js/${store?.emarsysAnalyticsId}/scarab-v2.js';
            var fs = document.getElementsByTagName('script')[0];
            fs.parentNode.insertBefore(js, fs);
          })('scarab-js-api');
        `}</script>
        <script type="text/javascript">
          {`
            window.optimizely = window.optimizely || [];
            window.optimizely.push({
              "type": "waitForOriginSync",
              "canonicalOrigins": ["${store.url}","${store.shopifyCheckoutUrl}"]
            });
        `}
        </script>

        {search ? <meta name="robots" content="noindex, follow" /> : <meta name="robots" content="index, follow" />}

        {languages?.map(({ key, href, hrefLang, rel }) => (
          <link key={key} href={href} hrefLang={hrefLang} rel={rel} />
        ))}

        {schemas.map((schema, index) => (
          <script type="application/ld+json" key={index}>
            {JSON.stringify(schema)}
          </script>
        ))}

        <script defer src="//cdn.shopify.com/shopifycloud/shop-js/client.js"></script>
        <script src={`//cdn.optimizely.com/js/${optimizelyID}.js`} />
      </Helmet>

      {tracking.map(script => script)}

      {banner?.enabled && (
        <CookieConsent
          location="bottom"
          buttonText={banner.buttonText}
          declineButtonText={banner.declineButtonText}
          cookieName={gdprCookieName}
          style={{ background: "#fff", borderTop: "3px solid black", color: "black" }}
          buttonStyle={{
            color: "#fff",
            background: "black",
            fontSize: "13px",
            borderRadius: "24px",
            border: "2px solid black",
            margin: "15px 10px",
          }}
          declineButtonStyle={{
            color: "#000",
            background: "white",
            fontSize: "13px",
            borderRadius: "24px",
            border: "2px solid black",
            margin: "15px 0px 15px 10px",
          }}
          expires={150}
          enableDeclineButton
          overlayStyle={{
            backgroundColor: "rgba(0, 0, 0, 0.9)",
          }}
          onAccept={() => trackGDPRConsent()}
          onDecline={() => null}
        >
          {sanityContent(banner?.message)}
        </CookieConsent>
      )}
      {/* Skip Navigation */}
      <Link
        to="#main"
        position={"absolute"}
        top={0}
        left={0}
        w={0}
        h={0}
        fontSize="lg"
        opacity="0"
        _focus={{
          opacity: "1",
          bgColor: "primary.white",
          p: 7.5,
          borderRadius: 2,
          border: "3px solid rgba(66, 153, 225, 0.6)",
          zIndex: "9999",
          w: "auto",
          h: "auto",
        }}
      >
        SKIP TO CONTENT
      </Link>
    </>
  )
}

export default React.memo(Meta)
