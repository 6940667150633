import React, { useMemo, useEffect } from "react"
import { Box, BoxProps, Flex } from "@chakra-ui/react"

import { useAppContext } from "@app/providers/app"
import NavigationHeaderItem from "@app/components/Navigation/Header/NavigationHeaderItem"
import NavigationHeaderMega from "@app/components/Navigation/Header/NavigationHeaderMega"
import NavigationHeaderDropdown from "@app/components/Navigation/Header/NavigationHeaderDropdown"
import MotionBox from "@app/components/MotionBox"

type Props = {
  items: Array<GatsbyTypes.SanityNavigation["items"]>
  onHeaderToggle: (payload: any) => void
} & BoxProps

const NavigationHeader: React.FC<Props> = ({ items, onHeaderToggle, ...props }) => {
  const { state, headerRef } = useAppContext()

  const headerOffset = useMemo(
    () => {
      if (!headerRef?.current) return { height: 0, top: 0 }
      const headerRect = headerRef.current.getBoundingClientRect()
      return {
        height: headerRect.height + headerRect.top,
        top: headerRect.height - 1,
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state.activeHeader]
  )

  const handleResize = () => {
    onHeaderToggle(null)
  }

  useEffect(() => {
    window.addEventListener("resize", handleResize)
    handleResize()
    return () => window.removeEventListener("resize", handleResize)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return items?.length > 0 ? (
    <>
      <Flex display={{ base: "none", md: "flex" }} {...props}>
        {items?.map((item: any, index: number) => (
          <Box key={index}>
            {item?._type === "objectNewHeaderMega" ? (
              <NavigationHeaderMega item={item} headerOffset={headerOffset} />
            ) : item?._type === "newHeaderDropdown" ? (
              <NavigationHeaderDropdown item={item} headerOffset={headerOffset} onHeaderToggle={onHeaderToggle} />
            ) : item?._type === "headerLink" ? (
              <NavigationHeaderItem item={item} />
            ) : null}
          </Box>
        ))}
      </Flex>

      {state.activeHeader && (
        <MotionBox
          pos="absolute"
          h={`calc(100vh - ${headerOffset.height}px)`}
          zIndex={50}
          top={`${headerOffset.top}px`}
          bottom={0}
          left={0}
          right={0}
          onClick={() => {
            onHeaderToggle(null)
          }}
          animate={{ backdropFilter: "blur(25px)", transition: { duration: 0.3 } }}
          initial={{ backdropFilter: "blur(0)" }}
        />
      )}
    </>
  ) : null
}

export default React.memo(NavigationHeader)
